import React, { ReactNode } from 'react';
import classNames from 'classnames';
import * as styles from './style.module.scss';

type HeadlineProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  classLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  centered?: boolean;
  color?: 'Primary' | 'Secondary' | 'White' | 'Black';
  className?: string;
  children: ReactNode;
};

const Headline = ({ centered = false, children, classLevel, className, color = 'Black', level = 1 }: HeadlineProps) => {
  const HeadlineTag = ({ ...props }) => React.createElement(`h${level}`, props, children);
  const headlineClass = !classLevel ? level : classLevel;
  return (
    <HeadlineTag
      className={classNames(
        styles[`headline${headlineClass}`],
        {
          'text-center': centered,
          'text-primary': color === 'Primary',
          'text-black': color === 'Black',
          'text-white': color === 'White',
        },
        className
      )}
    >
      {children}
    </HeadlineTag>
  );
};

export default Headline;
