import React from 'react';
import HeaderImage from '../../../assets/images/header.svg';
import Headline from '../../ui/Headline';
import * as styles from './styles.module.scss';

const PageHeader = () => (
  <section className={styles.wrapper}>
    <div className={styles.contents}>
      <HeaderImage className="absolute right-0 z-0 hidden bottom-30 tablet:block" />
      <div className="z-10">
        <Headline level={2} color="White">
          Alle Leistungsbausteine rund ums Auto
        </Headline>
        <Headline level={1} color="White" className={styles.headline}>
          Abgefahren
          <br /> gut erklärt
        </Headline>
      </div>
    </div>
  </section>
);

export default PageHeader;
