import { Link } from 'gatsby';
import React from 'react';
import Icon from '../../components/ui/Icon';
import * as styles from './styles.module.scss';

const Header: React.FC = () => (
  <header className={styles.header}>
    <div className={styles.wrapper}>
      <Link to="/">
        <Icon name="logo" height={35} />
      </Link>
    </div>
  </header>
);

export default Header;
