// extracted by mini-css-extract-plugin
export var back = "styles-module--back--7DBFc";
export var buttonWrapper = "styles-module--buttonWrapper--Gv9LR";
export var close = "styles-module--close--NNVvh";
export var content = "styles-module--content--ICZN6";
export var description = "styles-module--description--bF+O3";
export var dialog = "styles-module--dialog--0xf+Y";
export var front = "styles-module--front--Y239v";
export var hexacontent = "styles-module--hexacontent--NLbi0";
export var hexagon = "styles-module--hexagon--xOzxA";
export var icon = "styles-module--icon--mZ321";
export var item = "styles-module--item--XXtj5";
export var itemsWrapper = "styles-module--itemsWrapper--wtHBT";
export var modal = "styles-module--modal--ub8mA";
export var overlay = "styles-module--overlay---Z7sm";
export var rotate = "styles-module--rotate--eblfA";
export var wrapper = "styles-module--wrapper--i8FUA";