import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import SEO from '../Seo';
import { SiteSettings } from './types';

type LayoutProps = {
  children: ReactNode;
  pageName: string;
  description: string;
};

const Layout = ({ children, pageName, description }: LayoutProps) => {
  const siteSettings: SiteSettings = {
    siteName: 'VGH',
    siteAuthor: 'VGH',
    email: 'email',
    phone: 'phone',
    copyright: 'copyright',
    ogImage: `${process.env.DOMAIN}/ogimage.jpg`,
    socialLinks: [
      {
        label: 'facebook',
        url: 'https://www.facebook.com/test',
      },
      {
        label: 'instagram',
        url: 'https://www.instagram.com/test/',
      },
    ],
    footerNav: [],
  };

  return (
    <>
      <SEO
        title={`${siteSettings.siteName} - ${pageName}`}
        description={description}
        author={siteSettings.siteAuthor}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
