import React from 'react';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

type Props = {
  className?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  label: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
};

const Button: React.FC<Props> = ({
  className = '',
  color = 'primary',
  disabled = false,
  label,
  loading = false,
  onClick = () => {},
  type = 'button',
}) => {
  return (
    <button
      className={classNames(
        className,
        styles.wrapper,
        { [styles.primary]: color === 'primary' },
        { [styles.secondary]: color === 'secondary' },
        { [styles.disabled]: disabled }
      )}
      type={type}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
