import React, { useState } from 'react';
import classNames from 'classnames';
import html2pdf from 'html2pdf.js';

import Modal from '../../ui/Modal';
import Icon from '../../ui/Icon';
import Button from '../../ui/Button';
import Headline from '../../ui/Headline';

import * as styles from './styles.module.scss';

const isBrowser = typeof window !== 'undefined';

const ItemsList: ItemsListType[] = [
  {
    id: 1,
    visible: true,
    label: 'Vollkasko',
    type: 'vehicle',
    title: 'Leistungsbeschreibung Vollkasko',
    items: [
      'Enthält alle Leistungen der Teilkasko',
      'Vandalismus',
      'Selbst verschuldete Unfälle',
      'Fahrerflucht des Unfallgegners',
      'Transport auf einem Schiff',
    ],
    selected: false,
  },
  {
    id: 2,
    visible: true,
    label: 'Kfz- Haftpflicht',
    type: 'compulsory',
    title: 'Leistungsbeschreibung Kfz-Haftpflichtversicherung',
    items: [
      'Pflichtversicherung für jedes Auto',
      'Begleichung berechtigter Forderungen von Unfallgegnern',
      'Abwehr unberechtigter Forderungen',
      'Personenschäden bis 15 Mio. €',
      'Sach- u. Vermögensschäden bis 100 Mio. €',
      'Mallorca-Police (bei Komfort)',
    ],
    description: '',
    selected: true,
    disabled: true,
  },
  {
    id: 3,
    visible: true,
    label: 'Insassenschutz',
    type: 'friend',
    title: 'Leistungsbeschreibung Insassenschutz',
    items: [
      'Unfallversicherung für alle Insassen',
      'Deckung von Unfällen, die unmittelbar mit dem Gebrauch Ihres Fahrzeugs zusammenhängen',
    ],
    description: '',
    selected: false,
  },
  {
    id: 4,
    visible: true,
    label: 'Teilkasko',
    type: 'vehicle',
    title: 'Leistungsbeschreibung Teilkasko',
    items: [
      'Diebstahl, Raub, Unterschlagung',
      'Brand, Explosion, Sturm, Hagel, Überschwemmung, (Dach-)Lawine',
      'Erdbeben und Vulkanausbruch',
      'Zusammenstoß mit Tieren aller Art (für Basis nur Haarwild nach BJagdG)',
      'Glasbruch/-reparatur',
      'Tierbiss und Folgen bis<br/>10.000 €',
      'Mitversicherung von Fahrzeug- und Zubehörteilen bis 5.000 € (Komfort) bzw. 10.000 € (Premium)',
    ],
    description: '',
    selected: false,
  },
  {
    id: 5,
    visible: true,
    isLogo: true,
    label: '',
    type: 'compulsory',
    title: '',
    items: [],
    description: '',
    selected: false,
  },
  {
    id: 6,
    visible: true,
    label: 'Fahrerschutz',
    type: 'friend',
    title: 'Leistungsbeschreibung Fahrerschutz',
    items: [
      'Schutz vor finanziellen Folgen eines Unfalls für alle berechtigten Fahrer',
      'Übernahme z.B. von finanziellen Einbußen durch Verdienstausfall',
    ],
    description: '',
    selected: false,
  },
  {
    id: 7,
    visible: true,
    label: 'E-Premium',
    type: 'vehicle',
    title: 'Leistungsbeschreibung E-Premium*',
    items: [
      'Kurzschlussschäden (inkl. Folgeschäden) bis 20.000 €',
      'Tierbissschäden (inkl. Folgeschäden) bis 20.000 €',
      'All-Risk-Deckung für Akku**',
      'Eigene Wandladestationen und eigene mobile Ladestation bis 3.000 €',
      'Ladekarten bis 100 €',
      'Induktionsladeplatte bis 1.000 € Entsorgungskosten für den Akku',
    ],
    description: `
    * Gilt für Elektro- oder von außen aufladbare Hybrid-Pkw und Elektro- oder von außen aufladbare Lieferwagen (Werkverkehr bis 3,5 t)<br/>
    ** (Ausnahmen: Abnutzung, Herstellerfehler etc.)`,
    selected: false,
  },
  {
    id: 8,
    visible: true,
    label: 'Autoschutz- brief',
    type: 'additional',
    title: 'Leistungsbeschreibung Autoschutzbrief',
    items: [
      'Hilfe bei Pannen und Unfällen',
      'Hilfe bei Diebstahl Ihres Fahrzeugs',
      'Europaweiter Geltungsbereich',
      'Übernahme medizinisch notwendiger Rücktransporte',
    ],
    description: '',
    selected: false,
  },
  {
    id: 9,
    visible: true,
    label: 'Werkstatt- service',
    type: 'additional',
    title: 'Leistungsbeschreibung Werkstattservice <br/>(nur für Pkw)',
    items: [
      'reduzierter Kaskobeitrag',
      'im Schadenfall Auswahl der Werkstatt durch uns',
      'Hol- u. Bringservice ab 15 km',
      'gratis Innenraumreinigung bei Werkstattaufenthalt – sämtliche Garantien bleiben erhalten',
    ],
    description: '',
    addition: '',
    selected: false,
  },
  {
    id: 13,
    visible: false,
  },
  {
    id: 14,
    visible: true,
    label: 'Rabattschutz',
    type: 'additional',
    title: 'Leistungsbeschreibung Rabattschutz*',
    items: [
      'Keine Rückstufung, wenn ein (1) belastender Schaden pro Kalenderjahr eintritt',
      'Sicherung Ihres Schadenfreiheitsrabatts',
      'nur in Verbindung mit einer Kfz-Haftpflicht Komfort mit Autoschutzbrief',
    ],
    description:
      '* Voraussetzung für die Vereinbarung des Rabattschutzes in der Kfz-Haftpflichtversicherung und der Vollkasko/Vollkasko Premium/E-Premium ist, dass der Vertrag in beiden Sparten mind. in die SF-Klasse 5 eingestuft ist und bei Abschluss kein Schaden im laufenden Kalenderjahr verursacht worden ist. Die Laufzeit beträgt mind. 12 Monate.',
    selected: false,
  },
  {
    id: 15,
    visible: false,
  },
];

const StepSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItemsList, setCurrentItemsList] = useState(ItemsList);
  const [modalData, setModalData] = useState<ItemsListType>();
  const [showModal, setShowModal] = useState(false);

  const confirmItem = (idx: number) => {
    setCurrentIndex(idx);
    setModalData({ ...currentItemsList[idx] });
    setShowModal(true);
  };

  const setIsImportant = (val: boolean) => {
    setShowModal(false);
    setCurrentItemsList(prev => {
      prev[currentIndex].selected = val;
      return [...prev];
    });
  };

  const generatePDF = () => {
    const element = document.querySelector('#report') as HTMLElement;
    if (isBrowser) {
      html2pdf(element, {
        html2canvas: { scale: 4 },
        filename: `VGH-Ihre-Auswahl.pdf`,
        margin: 12,
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.mobileMessage}>Um die Produkte sehen zu können, benötigst du ein größeres Display.</div>
      <div className={styles.honeycomb}>
        {currentItemsList.map((item, index) => (
          <div
            className={classNames(styles.hexagon, styles[item.type ?? ''], { invisible: !item.visible })}
            key={item.id}
            role="button"
            onClick={() => {
              if (!item.isLogo) confirmItem(index);
            }}
            aria-hidden
          >
            <div className={styles.hexagontent}>
              {!item.isLogo ? item.label : <Icon name="carPlus" width={130} height={175} className="mx-auto"></Icon>}
            </div>
            {item.addition && <div className={styles.addition}>{item.addition}</div>}
            <Icon
              name="checkBackground"
              width={60}
              className={classNames(styles.selectedIcon, { [styles.activated]: item.selected })}
            />
          </div>
        ))}
      </div>
      <p className={classNames(styles.description, styles.compulsory)}>Pflichtversicherung</p>
      <p className={classNames(styles.description, styles.vehicle)}>
        ergänzender Versicherungs- schutz für Ihr Fahrzeug
      </p>
      <p className={classNames(styles.description, styles.friend)}>zusätzliche Sicherheit für Sie und Ihre Liebsten</p>
      <p className={classNames(styles.description, styles.additional)}>weitere Leistungen</p>
      <div
        className={classNames(styles.buttonWrapper, {
          [styles.hide]: currentItemsList.filter(item => item.selected).length === 0,
        })}
      >
        <Button label="Auswahl als PDF" className="align" onClick={() => generatePDF()} />
      </div>
      <div className="hidden">
        <div id="report" className={styles.report}>
          <div className="flex justify-between">
            <div>
              <span className="text-12">Die Kfz-Versicherung</span>
              <Headline classLevel={3} className="mt-5">
                <>
                  Weil Sicherheit
                  <br />
                  das Wichtigste ist
                </>
              </Headline>
            </div>
            <Icon name="logoClaim" width={150} className="-mt-20" />
          </div>
          <Headline classLevel={4} className={styles.subline}>
            Ihre Auswahl im Überblick:
          </Headline>
          <div className="grid grid-cols-3 gap-x-20 gap-y-60">
            {currentItemsList
              .filter(item => item.selected)
              .filter(item => item.visible)
              .map((item, index) => (
                <div key={index}>
                  <div className={styles.hexagon}>
                    <div className={styles.hexagontent}>
                      {item.label?.replace('Kfz- Haftpflicht', 'Kfz-Haftpflicht')}
                    </div>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.title ?? '',
                    }}
                    className="mt-20 mb-30 text-12"
                  />
                  <div className={styles.itemsWrapper}>
                    {item?.items?.map((dItem: string, dIndex: number) => (
                      <div key={dIndex} className={styles.item}>
                        <Icon name="check" className={styles.icon} width={15} height={15} />
                        <span className="text-12 leading-16 inline-block -mt-[3px]">{dItem}</span>
                      </div>
                    ))}
                  </div>
                  {item?.title2 && (
                    <p
                      className="mt-20 mb-30 text-12"
                      dangerouslySetInnerHTML={{
                        __html: item?.title2 ?? '',
                      }}
                    />
                  )}
                  {item?.items2 && (
                    <div className={styles.itemsWrapper}>
                      {item?.items2.map((dItem: string, dIndex: number) => (
                        <div key={dIndex} className={styles.item}>
                          <Icon name="check" className={styles.icon} width={15} height={15} />
                          <span className="text-12 leading-16 inline-block -mt-[3px]">{dItem}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  <p
                    className="pb-20 text-10 leading-16"
                    dangerouslySetInnerHTML={{
                      __html: item?.description ?? '',
                    }}
                  />
                  {(index === 2 || index === 5) && (
                    <>
                      <div className="html2pdf__page-break"></div>
                      <div className="flex justify-between mt-8">
                        <div></div>
                        <Icon name="logoClaim" width={150} className="-mt-20" />
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => setShowModal(false)}
        isShown={showModal}
        modalData={modalData}
        setIsImportant={setIsImportant}
      />
    </section>
  );
};

export default StepSection;
