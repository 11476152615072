import React from 'react';
import Helmet from 'react-helmet';

type SEOProps = {
  description?: string;
  lang?: 'de';
  meta?: Array<string>;
  keywords?: Array<string>;
  title: string;
  author?: string;
  ogImage?: string;
};

const SEO = ({
  description,
  lang = 'de',
  meta = [],
  keywords = [],
  title,
  author,
  ogImage = `${process.env.DOMAIN}/ogimage.jpg`,
}: SEOProps) => {
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: ogImage,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: ogImage,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

export default SEO;
