import React from 'react';
import carPlus from './icons/car_plus.svg';
import check from './icons/check.svg';
import checkBackground from './icons/check_bg.svg';
import close from './icons/close.svg';
import facebook from './icons/facebook.svg';
import instagram from './icons/instagram.svg';
import logo from './icons/logo.svg';
import logoClaim from './icons/logoClaim.svg';

export type IconType =
  | 'carPlus'
  | 'check'
  | 'checkBackground'
  | 'close'
  | 'facebook'
  | 'instagram'
  | 'logo'
  | 'logoClaim';

type IconProps = {
  name: IconType;
  width?: number;
  height?: number;
  className?: string;
};

const Icon = ({ name, width, height, className }: IconProps) => {
  if (!name) {
    return null;
  }

  const icons = {
    carPlus,
    close,
    logo,
    facebook,
    instagram,
    check,
    logoClaim,
    checkBackground,
  };

  const CurrentIcon = icons[name];

  if (!CurrentIcon) return null;

  return <CurrentIcon width={width} height={height} className={className} />;
};

export default Icon;
