import React from 'react';

import Layout from '../modules/Layout';
import PageHeader from '../components/content/PageHeader';
import StepSection from '../components/content/StepSection';

const HomePage = () => {
  return (
    <Layout pageName="Die Kfz-Versicherung" description="Alle Leistungsbausteine rund ums Auto">
      <PageHeader />
      <StepSection />
    </Layout>
  );
};

export default HomePage;
